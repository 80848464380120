<template>
  <div class="witness">
    <div class="left-witness">
      <p>
        {{ cms.page.fgSharingTheGospel }}
      </p>
    </div>
    <div class="right-witness">
      <img
          src="../assets/images/witness/Testaments_3.png"
          class="img-fluid invite_friend_img"
      />
    </div>
  </div>
  <div class="witness-container">
    <div class="witness_header">
      <h2 class="title-underline">
        {{ cms.page.fgConsiderTheFollowing }}
      </h2>
    </div>
    <div class="witness_content">

      <aside>
        <div
            class="sidebar-content"
            id="sidebar-content"
            :class="sidebarStyles"
            ref="sidebarContent"
        >
          <div
              class="witness_name"
              :class="
              visibleTestimony === testimonial.id
                ? 'witness_highlight'
                : 'witness_no_highlight'
            "
              v-for="testimonial in Testimony"
              v-scroll-to="{
              el: `#section-${testimonial.id}`,
              offset: testimonial.id === 1 ? designStore.heightHeader * -1 - 150 : designStore.heightHeader * -1 - 20,
            }"
              :key="testimonial"
              @click="clickWitness(testimonial.id)"
          >
            <p>
              {{ testimonial.name }}
            </p>
          </div>
        </div>
      </aside>

      <div class="witness_testimony_content">
        <main
            class="main"
            id="main"
            ref="mainContent"
        >
          <!-- Write your Personal Testimony -->
          <div id="section-1" class="section-wrapper">
            <b class="title">{{ cms.page.fgWriteYourPersonal }}</b>
            <p>
              {{ cms.page.fgAnImportantPart }}
            </p>
          </div>
          <!-- Understand the Gospel -->
          <div id="section-2" class="witness_new section-wrapper">
            <b class="title"> {{ cms.page.fgUnderstandTheGospel }}</b>
            <p>
              {{ cms.page.fgAtTheMostBasicLevel }}
            </p>
            <div
                v-for="(news, index) in tableWitness"
                :key="index"
                class="table_witness_news"
            >
              <span class="key">{{ news.id }}</span>
              <div class="content">
                <p>{{ news.name }}</p>
                <h4>{{ news.roman }}</h4>
              </div>
            </div>
          </div>
          <!-- Study the Basics of Sharing -->
          <div id="section-3" class="approach section-wrapper">
            <b class="title">{{ cms.page.fgStudyTheBasics }}</b>
            <p>
              {{ cms.page.fgAfterSharingAVersion }}
            </p>
            <table>
              <tr>
                <th>{{ cms.page.fgAcronym }}</th>
                <th>{{ cms.page.fgStandsFor }}</th>
                <th>{{ cms.page.fgDescription }}</th>
              </tr>
              <tr>
                <td>{{ cms.page.fgL }}</td>
                <td>{{ cms.page.fgListen }}</td>
                <td>{{ cms.page.fgLetThePerson }}</td>
              </tr>
              <tr>
                <td>{{ cms.page.fgE }}</td>
                <td>{{ cms.page.fgEmpathize }}</td>
                <td>{{ cms.page.fgLookForAnOpportunity }}</td>
              </tr>
              <tr>
                <td>{{ cms.page.fgA }}</td>
                <td>{{ cms.page.fgAsk }}</td>
                <td>{{ cms.page.fgAskForPermission }}</td>
              </tr>
              <tr>
                <td>{{ cms.page.fgP }}</td>
                <td>{{ cms.page.fgPray }}</td>
                <td>{{ cms.page.fgIfThePersonIsSpiritually }}</td>
              </tr>
            </table>
            <div class="show-mobile">
              <div class="item">
                <p>{{ cms.page.fgLListen }}</p>
                <p>{{ cms.page.fgLetThePerson }}</p>
              </div>
              <div class="item">
                <p>{{ cms.page.fgEEmpathize }}</p>
                <p>
                  {{ cms.page.fgLookForAnOpportunity }}
                </p>
              </div>
              <div class="item">
                <p>{{ cms.page.fgAAsk }}</p>
                <p>{{ cms.page.fgAskForPermission }}</p>
              </div>
              <div class="item">
                <p>{{ cms.page.fgPPray }}</p>
                <p>
                  {{ cms.page.fgIfThePersonIsSpiritually }}
                </p>
              </div>
            </div>
          </div>
          <!-- Using the Testaments -->
          <div v-if="authStore.isUSAMember" id="section-4" class="friends_testaments section-wrapper">
            <b class="title">{{ cms.page.fgUsingTheTestaments }}</b>
            <p>
              {{ cms.page.fgThe }}
              <router-link :to="{ name: 'Store'}">
                <strong class="strong">{{ cms.page.fgFriendsTestaments }}</strong>
              </router-link>
              {{ cms.page.fgAvailableInTheStore }}
              <strong>
                <router-link class="store-link" :to="{ name: 'Store'}">{{ cms.page.fgStore }}</router-link>
              </strong>

              {{ cms.page.fgIncludeHelpfulSections }}
            </p>
            <div class="witness_btn">
              <router-link :to="{ name: 'Store'}">
                <button class="btn btn-default" type="submit">
                  {{ cms.page.fgStore }}
                </button>
              </router-link>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="witness_conversation">
      <footer class="inner-wrapper" ref="footer">
        <p v-html="cms.page.fgBottomNote"></p>
      </footer>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore";
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";
import _ from 'lodash';
import inView from "in-view";

export default {
  name: "Witness",
  data() {
    return {
      cms: {
        loaded: false,
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "witnessing",
          MinistryUpdates: false,
        },
        page: {},
      },
      Testimony: [
        {name: "Write Your Personal Testimony", id: 1},
        {name: "Understand The Gospel", id: 2},
        {name: "Study The Basics of Sharing", id: 3},
        {name: "Using the Testaments", id: 4},
      ],
      tableWitness: [
        {id: 1, name: "  Jesus Loves You", roman: "John 3:16"},
        {id: 2, name: "  All Are Sinners", roman: "Romans 3:23, Romans 3:10"},
        {
          id: 3,
          name: " God's Remedy For Sin",
          roman: "Romans 3:23, Romans 3:10",
        },
        {
          id: 4,
          name: " All May Be Saved",
          roman: "Romans 10:9-10, Romans 10:13   ",
        },
      ],
      sidebar: {
        height: 0,
        windowHeight: 0,
        windowScrollTop: 0,
        offsetTop: 0
      },
      main: {
        height: 0,
        windowHeight: 0,
        windowScrollTop: 0,
        offsetTop: 0,
        offsetBottom: 0
      },
      section0: {
        offsetTop: 0
      },
      section1: {
        offsetTop: 0
      },
      section2: {
        offsetTop: 0
      },
      section3: {
        offsetTop: 0
      },
      visibleTestimony: 1,
    };
  },
  components: {},
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore),
    sidebarStyles() {
      if ((this.main.offsetTop <= 180) && (this.main.offsetBottom >= (this.sidebar.height * 1.5)))
        return {'fixed-top': true}
    },
  },
  async created() {
    this.visibleTestimony = 1;
    this.setContentLoaded(false);
    this.setTitlePageFromCms()
    await this.fetchCmsData();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);

  },
  mounted() {
    this.calculateSidebar()
    this.calculateMain()
  },
  methods: {
    clickWitness(id) {
      this.visibleTestimony = id;
    },
    handleClicked(icon) {
      this.currentIcon = icon;
    },
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setTestimonyContent();
              this.setTableOfWitnessContent();
              this.setTitlePageFromCms(results)
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    setTestimonyContent() {
      this.Testimony = this.Testimony.map((item) => {
        // Non-USA Members cannot see the 4th item
        if (!this.authStore.isUSAMember && item.id === 4) {
          return {};
        }

        return {
          name: this.cms.page[`fgTestimony_${item.id}`],
          id: item.id,
        };
      });
    },
    setTableOfWitnessContent() {
      this.tableWitness = this.tableWitness.map((item) => {
        return {
          id: item.id,
          name: this.cms.page[`fgTableWitnessName_${item.id}`],
          roman: this.cms.page[`fgTableWitnessRoman_${item.id}`],
        };
      });
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setTitleContent() {
      this.designStore.setTitlePage({
        title: this.cms.page.fgWitnessingHelp, // "witnessing help",
        sub_title: this.cms.page.fgHowToShareYourFaith, // "how to share your faith",
        img_class: "bg-friend",
        btn_history: false,
      });
    },

    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgWitnessingHelp ? page.fgWitnessingHelp : 'Witnessing Help'
      const pageSubtitle = page && page.fgHowToShareYourFaith ? page.fgHowToShareYourFaith : 'how to share your faith'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "bg-friend",
        btn_history: false,
      });
    },
    handleResize: _.throttle(function () {
      this.calculateSidebar()
      this.calculateMain()
    }, 100),
    handleScroll: _.throttle(function () {
      var rect = document.getElementById('sidebar-content').getBoundingClientRect();
      var rect2 = document.getElementById('main').getBoundingClientRect();
      var allSections = document.getElementsByClassName('section-wrapper');
      // sidebar
      this.sidebar.windowScrollTop = window.pageYOffset || document.documentElement.scrollTop
      this.sidebar.offsetTop = rect.top
      // main content
      this.main.windowScrollTop = window.pageYOffset || document.documentElement.scrollTop
      this.main.offsetTop = rect2.top
      this.main.offsetBottom = rect2.bottom

      // console.log(this.main.offsetTop)
      inView(".title-underline")
          .on("enter", (el) => {
            el.classList.add("inview");
          })
          .on("exit", (el) => {
            el.classList.remove("inview");
          });

      for (let i = 0; i < allSections.length; i++) {
        var el = allSections[i].getBoundingClientRect();
        var aSectionOffset = this[`section${i}`].offsetTop;
        aSectionOffset = el.top;

        if ((aSectionOffset > 0) && (aSectionOffset < 200)) {
          this.visibleTestimony = i + 1
        }
      }


    }, 100),
    calculateSidebar() {
      this.sidebar.height = this.$refs.sidebarContent.offsetHeight
      this.sidebar.windowHeight = window.innerHeight
    },
    calculateMain() {
      this.main.height = this.$refs.mainContent.offsetHeight
      this.main.windowHeight = window.innerHeight
    }

  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.witness {
  display: flex;
  justify-content: space-between;
  margin-top: -25px;
  align-items: center;
  @media screen and (max-width: $lg) {
    flex-wrap: wrap;
  }

  .left-witness {
    width: 60%;
    background: #28677d;
    color: #fff;
    padding: 110px 150px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $xxl) {
      min-height: 286px;
    }
    @media screen and (max-width: $xl) {
      min-height: 263px;
    }
    @media screen and (max-width: $lg) {
      padding: 46px 18px;
      min-height: 210px;
      width: 100%;
    }
    @media screen and (max-width: $sm) {
      padding: 27px 18px;
      min-height: 265px;
    }
    @media (min-width: 992px) {
      opacity: 0;
      animation: fadeInLeft 1s ease forwards 1s;
    }

    p {
      margin-bottom: 0;
    }
  }

  .right-witness {
    position: relative;
    width: 40%;
    z-index: 999;
    margin: auto;
    margin-left: -35px;
    @media screen and (max-width: $lg) {
      width: calc(100% - 30px);
      margin: -25px auto 0;
    }
    @media (min-width: 992px) {
      height: 320px;
      opacity: 0;
      animation: fadeInRight 1s ease forwards 1s;
    }

    img {
      @media (min-width: 992px) {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.witness-container {
  padding: 110px 25px 15px;
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  @include MQ(S) {
    padding: 50px 15px 15px;
  }

  .witness_header {
    width: 680px;
    max-width: 100%;
    position: relative;
  }

  * {
    box-sizing: border-box;
  }

  .witness_content {
    display: flex;
    margin-top: 5rem;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    vertical-align: baseline;
    @media (max-width: 991px) {
      margin-top: 15px;
    }

    aside {
      position: relative;
      height: 100%;

      .sidebar-content {
        width: 320px;
        position: relative;
        @media (max-width: $lg) {
          display: none;
        }

        .witness_name {
          cursor: pointer;
          background: #f9fafa;
          transition: all .33s ease;
          padding: 2px 10px;
          margin-bottom: 10px;

          &.witness_highlight {
            background: #28677d;
            color: white;
          }

          p {
            margin: 0;
            font-weight: 500;
          }
        }

        &.fixed-top {
          position: fixed;
          top: 190px;
        }

        &.fixed-bottom {
          position: fixed;
          bottom: 0;
        }
      }
    }


    .sidebar-wrapper {
      flex-basis: 320px;
      width: 320px;
      position: relative;
      @media screen and (max-width: $lg) {
        display: none;
      }

      .sidebar-fixed {
        position: relative;
      }
    }

    .witness_testimony_content {
      flex-basis: calc(100% - 450px);
      width: calc(100% - 450px);
      position: relative;
      @media screen and (max-width: $lg) {
        width: 100%;
        flex-basis: 100%;
        padding-left: 0;
      }

      p {
        font-size: 18px;
        @media screen and (max-width: $sm) {
          font-size: 16px;
        }
      }

      .friends_testaments {
        margin-bottom: 50px;

        .strong {
          color: #f4991f;
        }
      }

      .witness_btn {
        margin-top: 50px;
        @media screen and (max-width: $lg) {
          display: none;
        }

        @media screen and (max-width: $md) {
          margin-top: 100px;
        }
        @media screen and (max-width: $md) {
          margin-top: 0;
        }

        button {
          text-transform: capitalize;
          @media screen and (max-width: $md) {
            margin-top: 100px;
          }
        }
      }

      .section-wrapper {
        position: relative;
        display: block;
        @media (min-width: 992px) {
          padding-top: 120px;
        }

        &#section-1 {
          padding-top: 0;
        }

        .title {
          display: none;
          text-transform: uppercase;
          padding: 40px 0;
          margin-top: 110px;
          border-top: 1px solid #000000;

          @media screen and (max-width: $lg) {
            display: block;
            font-size: 2rem;
          }
          @media screen and (max-width: $sm) {
            margin-top: 0;
            font-size: 1rem;
          }
        }

        &:nth-child(1) {
          .title {
            margin-top: 0;
            border-top: 0;
          }
        }
      }

      @media screen and (min-width: $lg) {
        min-height: 320px;
      }
    }

    .witness_orderList {
      display: flex;
      align-items: center;

      p {
        font-weight: 500;
      }
    }
  }

  .table_witness_news {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    .key {
      @include MQ(M) {
        min-width: 11px;
      }
    }

    .content {
      display: flex;
      align-items: center;
      margin-left: 18px;

      @media screen and (max-width: $md) {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 8rem;
      }
      @media screen and (max-width: $sm) {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 3rem;
      }

      p {
        width: 300px;
        margin-bottom: 0;
      }

      h4 {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: $md) {
      margin: 16px 0;

      .key {
        font-size: 30px;
        font-weight: 900;
        color: #82929d;
      }
    }
  }

  .approach {
    table {
      margin: 25px 0;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid rgba(black, .2);
      @include MQ(S) {
        display: none;
      }

      td,
      th {
        border-right: 1px solid rgba(black, .2);
        padding: 25px;
      }

      th {
        border-bottom: 1px solid rgba(black, .2);

        &:last-child {
          text-align: left;
        }
      }

      tr {
        td:not(:last-child) {
          text-align: center;
          font-weight: 500;
        }
      }
    }

    .show-mobile {
      display: none;
      @media screen and (max-width: $md) {
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0 0;
      }

      .item {
        margin: 0 0 20px;

        &:last-child {
          margin: 0;
        }
      }

      p {
        &:first-child {
          font-weight: bold;
        }
      }
    }

    .text-center {
      text-align: center;
    }
  }

  .witness_conversation {
    border-top: 2px solid #c2cacf;
    margin-top: 75px;
    position: relative;

    .inner-wrapper {
      padding: 15px;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      margin-top: 40px;
    }
  }

  .store-link {
    text-transform: capitalize;
    color: $yellow-darker;
  }
}
</style>
